import BaseBean from "@/utils/BaseBean";

export interface IToolBrandCardDataObj {
    utilInst:ToolBrandCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
}
export default class ToolBrandCardUtil extends BaseBean{
    public dataObj:IToolBrandCardDataObj;

    constructor(proxy:any,dataObj:IToolBrandCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        //this.dataObj.networkData=await this.utils.ToolsProviderApi.buildNetworkData();
    }
}