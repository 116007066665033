import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import ToolBrandCardUtil,{IToolBrandCardDataObj} from './toolBrandCardUtil';
import {useRoute} from "vue-router";
export default defineComponent({
    name:'ToolBrandCard',
    title:'品牌管理',
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let route:any = useRoute();//当前路由对象
        provide('uploadOwner', proxy);
        let dataObj:IToolBrandCardDataObj=reactive<IToolBrandCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: false,
                modelPath: utils.ToolsProviderApi.buildUrl('/toolBrand')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                type: [utils.UtilPub.commonValidRule('请选择类别')],
                name: [utils.UtilPub.commonValidRule('请输入名称')]
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ToolBrandCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'type'==params.comboId){
                    return [{value:0,label:'金杯'},{value:1,label:'斯威'},{value:2,label:'新能源'},{value:3,label:'其它'}]
                }
            }
        })
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return Object.assign({
                    belongMaxId:dataObj.form.id,
                    fileSize:10,
                    saveType:1,
                    limitNum:1,
                    listType:'picture-card',
                    accept:'.jpg,.JPG,.png,.PNG,.jpeg,.JPEG',
                    showFileList:true
                },params)
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue:string,selectId:string)=>{
            if('type'==selectId){}
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            dataObj.form.flag=engineInst.engineParams.queryParam.flag;
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        const setParam=(engineInst:any)=>{
            let flag=0;
            if(route.path=='/serviceStationToolBrand')flag=1;
            return {flag:flag}
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,beforeOpen,beforeSaveHandler,setParam,
            buildUploadInitParams
        }
    }
});