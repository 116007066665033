import BaseBean from "@/utils/BaseBean";

export interface IToolBrandListDataObj {
    utilInst:ToolBrandListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams:any
}

export default class ToolBrandListUtil extends BaseBean{
    public dataObj:IToolBrandListDataObj

    constructor(proxy:any,dataObj:IToolBrandListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        //this.dataObj.networkData=await this.utils.Api.buildNetworkData();
    }
}