import ToolBrandCard from './ToolBrandCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, onActivated,nextTick} from 'vue';
import ToolBrandListUtil,{IToolBrandListDataObj} from './toolBrandListUtil';
import {TableColumnCtx} from "element-plus/es/components/table/src/table-column/defaults";
export default defineComponent ({
    name: 'ToolBrandList',
    beforeRouteEnter(to:any,from:any,next:any){
        next((curProxyInst:any)=>{
            if(to.path=='/cxToolBrand')curProxyInst.pageList.queryParam.flag=0;
            else curProxyInst.pageList.queryParam.flag=1;
            curProxyInst.pageListRef.queryHandler();
        })
    },
    setup(){
        const {ctx:that, proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IToolBrandListDataObj=reactive<IToolBrandListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {
                    flag:0
                },
                modelComp: ToolBrandCard,
                modelMethod: utils.ToolsProviderApi.buildUrl('/toolBrand/pageData')
            },
            otherParams:{

            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ToolBrandListUtil(proxy,dataObj);
        })

        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })

        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'type'==params.comboId){
                    return [{value:0,label:'金杯'},{value:1,label:'斯威'},{value:2,label:'新能源'},{value:3,label:'其它'}]
                }
            }
        })
        const setDelNoteMsg=async (row:any)=>{
            let message="删除品牌后，该品牌下方的车型及车型关联的车辆手册将被一并删除。";
            if(dataObj.pageList.queryParam.flag==1){
                message="删除品牌后，该品牌下方关联的服务站通讯录将被一并删除。";
            }
            return message;
        }
        return{
            ...toRefs(dataObj),comboSelect,setDelNoteMsg
        }
    }
});